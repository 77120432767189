import React, { useState, useEffect } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import BlockIcon from '../../assets/img/icons/ic-key-negative.svg';
import PositivePlusIcon from '../../assets/img/icons/ic-key-positive.svg';
import PropertyReviewInfoBlock from './PropertyReviewInfoBlock/PropertyReviewInfoBlock';
import { getReviewPreviewData, objectIsNotEmpty } from '../../helpers/helper-methods';
import styles from './PropertyReview.module.scss';

const PropertyReview = ({ propertyId }) => {
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getReviewPreviewData(propertyId).then((resp) => {
      setPreviewData(resp);
      setLoading(false);
    })
      .catch(() => setLoading(false));
  }, [propertyId]);

  return (
    <div>
      {loading ? <Spinner spinnerClasses="spinner-primary" /> : previewData && objectIsNotEmpty(previewData) ? (
        <div className={styles.wrapper}>
          {previewData?.metaKeysArrPositive?.length
          || previewData?.metaKeysArrNegative?.length
          || previewData?.metaReviewSummary ? (
            <div className={`${styles.borderedBlock} ${styles.firstBlock}`}>
              <div className={`${styles.subtitle} t-700 uppercased-text`}>Meta-review</div>
              {previewData?.metaReviewSummary && (
                <div className={styles.blockInnerWrapper}>
                  <div className={`${styles.text} t-500`}>
                    {previewData.metaReviewSummary}
                  </div>
                </div>
              )}
            </div>
            ) : null}
          {previewData?.conclusionKeysArrPositive?.length
          || previewData?.conclusionKeysArrNegative?.length
          || previewData.suitability
          || previewData.conclusion ? (
            <div>
              <div className={`${styles.subtitle} t-700 uppercased-text`}>
                Conclusion
              </div>
              {previewData?.conclusion && (
                <div className={styles.blockInnerWrapper}>
                  <div className={`${styles.text} ${styles.textMargined} t-500`}>
                    {previewData.conclusion}
                  </div>
                </div>
              )}
              <div className={`${styles.list} flex flex-wrap`}>
                {previewData?.conclusionKeysArrPositive?.length
                  ? (
                    <PropertyReviewInfoBlock
                      containerStyles={styles.listBlock}
                      title="Key positive"
                    >
                      <PositivePlusIcon className={`${styles.icon}`} />
                      {previewData.conclusionKeysArrPositive.map((keyItem, index) => (
                        <div
                          key={`sdfao${index}`}
                          className={`${styles.listItem} t-500`}
                        >
                          {keyItem.note}
                        </div>
                      ))}
                    </PropertyReviewInfoBlock>
                  ) : null}
                {previewData?.conclusionKeysArrNegative?.length
                  ? (
                    <PropertyReviewInfoBlock
                      containerStyles={styles.listBlock}
                      title="Key negative"
                    >
                      <BlockIcon className={`${styles.icon}`} />
                      {previewData.conclusionKeysArrNegative.map((keyItem, index) => (
                        <div
                          key={`sdfsdfao${index}`}
                          className={`${styles.listItem} t-500`}
                        >
                          {keyItem.note}
                        </div>
                      ))}
                    </PropertyReviewInfoBlock>
                  ) : null}
              </div>
              {previewData?.suitability && (
                <div className={styles.blockInnerWrapper}>
                  <div className={`${styles.blockInnerTitle} t-600 uppercased-text`}>
                    Suitability
                  </div>
                  <div className={`${styles.text} t-500`}>
                    {previewData.suitability}
                  </div>
                </div>
              )}
            </div>
            ) : null}
          <div className={styles.blockInnerWrapper}>
            <div className={`${styles.blockInnerTitle} t-600 uppercased-text`}>
              Researched in
            </div>
            <div className={`${styles.text} t-500`}>
              {previewData.aboutRating}
            </div>
          </div>
          <div className="row">
            <div className={`${styles.blockInnerWrapper} col-12 col-md-6`}>
              <div className={`${styles.blockInnerTitle} t-600 uppercased-text`}>
                Rating Type
              </div>
              <div className={`${styles.text} t-500`}>
                {previewData.ratingType}
              </div>
            </div>
            <div className={`${styles.blockInnerWrapper} col-12 col-md-6`}>
              <div className={`${styles.blockInnerTitle} t-600 uppercased-text`}>
                Rating provider
              </div>
              <div className={`${styles.text} t-500`}>
                {previewData.reviewProvider}
              </div>
            </div>
          </div>
        </div>
      ) : <div className="emptyPlaceholder t-600 text-center">There is no published review yet</div>}
    </div>
  );
};

export default PropertyReview;
